<template>
  <div class="mx-auto mt-8 px-4 sm:px-4 lg:px-8">
    <div class="rounded-lg">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <div class="relative rounded-md shadow-sm">
            <select
              v-model="is_packed"
              @change="handleFilter"
              class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
            >
              <option :value="null">Semua</option>
              <option :value="true">Selesai</option>
              <option :value="false">Belum Selesai</option>
            </select>
          </div>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <SusulanTable
              @change-page="onPageChangeOrder"
              @click-row="handleClickItem"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Detail Modal -->
    <SupplierSusulanDetailModal
      :visible="openDetail"
      @close="handleClose"
      @refresh="handleRefresh"
      @detail-stock-movement="handleDetailStockMovement"
    />

    <stock-movement-modal
      :visible="openDetailStockMovement"
      title="Detail Pengiriman Barang"
      description="Data pengiriman barang dari Supplier"
      :with-dispute="false"
      :with-confirm="false"
      :with-send="false"
      with-validate
      with-batches
      force-display-first-packet
      view-as="origin"
      @close="handleCloseDetailStockMovement"
      @refresh="handleRefreshDetailStockMovement"
    >
      <template #origin-office="{ originOffice }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nomor Supplier</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ originOffice.attributes.code }}
          </dd>
        </div>
      </template>

      <template #additional-attribute="{ stockMovement }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Status Validasi</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-badge
              :color="
                stockMovement.data.attributes.is_valid_for_shipment
                  ? 'green'
                  : 'yellow'
              "
            >
              {{
                stockMovement.data.attributes.is_valid_for_shipment
                  ? 'Valid'
                  : 'Draft'
              }}</base-badge
            >
          </dd>
        </div>
      </template>
    </stock-movement-modal>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import SupplierSusulanDetailModal from '@/components/supplier/SupplierSusulanDetailModal';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import BaseBadge from '@/components/base/BaseBadge.vue';
import BaseSearch from '@/components/base/Search.vue';
import SusulanTable from '@/components/susulan/SusulanTable.vue';

export default {
  name: 'Home',
  components: {
    SupplierSusulanDetailModal,
    StockMovementModal,
    BaseBadge,
    BaseSearch,
    SusulanTable,
  },

  data() {
    return {
      search: null,
      is_packed: false,
      openDetail: false,
      openDetailStockMovement: false,
      dataBrgMasuk: {},
      detailBrgMasuk: {},
      page: {
        pageNumber: 1,
        pageSize: 5,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isOrderLoading: 'orders/getLoading',
      isStockMovementLoading: 'stock_movements/getLoading',
    }),
    isLoading() {
      return this.isOrderLoading || this.stockMovementLoading;
    },
    orderParams() {
      return {
        'filter[destination_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'page[limit]': this.page.pageSize,
        'filter[search]': this.search,
        'filter[order_status]': 'Ada Susulan',
        include: 'destination-office,origin-office,order-details,order-details',
        // fields: {
        //   orders:
        //     'warehouse_status,createdAt,updatedAt,origin_warehouse_code,origin_code,created_by,destination_code,final_price,is_completed,is_valid_for_payment,is_shipped,final_price,grand_total_price,grand_total_weight,item_count,destination-office,origin-office,order-details,stock-movements',
        //   offices: 'code,name,address,address2',
        //   'order-details':
        //     'product_code,product_qty,product_weight,product_weight,total_weight,product_not_received,total_price,product_packed',
        // },
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      updateStockMovement: 'stock_movements/updateStockMovement',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    async handleDetailStockMovement(id) {
      const res = await this.fetchStockMovement({
        id,
        include:
          'destination-office,origin-office,order,product-batches.product',
      });

      if (res) {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      }
    },
    handleCloseDetailStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = true;
    },
    handleRefreshDetailStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = false;
    },
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.page.pageNumber = page;
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    handleFilter() {
      this.fetchOrders({
        ...this.orderParams,
        pageNumber: 1,
      });
    },
    handleClickItem(data) {
      this.fetchOrder({
        id: data.attributes.order_id,
      }).then((res) => {
        this.detailBrgMasuk = res.data.data;
        this.openDetail = !this.openDetail;
      });
    },
    handleValidate(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.fetchOrders({
            ...this.orderParams,
          });
        }

        this.openDetail = false;
      });
    },
    handleClose() {
      this.openDetail = false;
    },
    handleRefresh() {
      this.openDetail = false;
      this.fetchOrders({
        ...this.orderParams,
      });
    },
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
  },
};
</script>
