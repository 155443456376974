<template>
  <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
    <div class="border-b border-gray-200 pb-5">
      <h3 class="text-lg font-bold leading-6 text-gray-900">
        Informasi Barang Keluar
      </h3>
      <p class="mt-2 max-w-4xl text-sm text-gray-500">
        Masukkan detail barang keluar. Klik pada baris untuk menambahkan batch
      </p>
    </div>
    <div class="my-2 flex sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <Datatable :paginated="false" :footer="false">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  KODE BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  NAMA BARANG / NO BATCH
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  JUMLAH
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  HARGA / TGL KADALUWARSA
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  TOTAL HARGA
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  AKSI
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="value.details.length">
              <template v-for="(barang, index) in value.details">
                <tr :key="index" class="bg-white hover:bg-green-50">
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    {{ barang.attributes.product_code }} ({{ barang.attributes.product_type === 'free' ? 'Reguler' : 'Promo' }})
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.attributes.product_name }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      :value="packets[index].product_not_received | toCurrency"
                      @input="(e) => handleProductInput(e, index)"
                      type="text"
                      name="no_batch"
                      class="block w-full border-0 bg-gray-50 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Jml. Produk"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.attributes.product_price }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ barang.attributes.total_price }}
                  </td>
                  <td
                    @click.stop="onRemoveProduct(index)"
                    class="flex items-center justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                  >
                    <Icon
                      icon="heroicons:x-mark-20-solid"
                      class="h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    />
                  </td>
                </tr>
                <tr
                  v-for="(item, i) in packets[index].batch"
                  class="bg-gray-50"
                  :class="packets[index].batch.length > 0 ? '' : 'hidden'"
                  :key="item.id + i + index"
                >
                  <td
                    class="flex justify-center whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    <div
                      v-if="i == packets[index].batch.length - 1"
                      @click="onPushBatch(index)"
                    >
                      <Icon
                        class="h-6 w-6 cursor-pointer text-green-600 hover:text-green-500"
                        icon="heroicons:plus-circle-20-solid"
                      />
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      v-model="packets[index].batch[i].attributes.no_batch"
                      type="text"
                      name="no_batch"
                      class="block w-full border-0 bg-gray-50 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan No. Batch"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      :value="
                        packets[index].batch[i].attributes.jumlah | toCurrency
                      "
                      @input="(e) => handleBatchInput(e, index, i)"
                      type="text"
                      name="jumlah"
                      class="block w-full border-0 bg-gray-50 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan Jumlah"
                    />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    <input
                      v-model="packets[index].batch[i].attributes.expired_at"
                      type="date"
                      name="expired"
                      class="block border-0 bg-gray-50 p-0 placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Tgl Kadaluwarsa"
                    />
                  </td>
                  <td></td>
                  <td
                    class="flex items-center justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    @click.stop="onRemoveItem(index, i)"
                  >
                    <Icon
                      icon="heroicons:x-mark-20-solid"
                      class="h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="8"
                >
                  Data tidak ditemukan
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>
    </div>
  </div>
</template>
<script>
import Datatable from '@/components/base/Datatable';

export default {
  name: 'SupplierBatchForm',
  components: {
    Datatable,
  },
  props: {
    value: {
      type: Object,
    },
    packets: {
      type: [Object, Array],
    },
  },
  methods: {
    onRemoveProduct(value) {
      this.$emit('remove-product', value);
    },
    onRemoveItem(index, i) {
      this.$emit('remove-batch', { index, i });
    },
    onPushBatch(value) {
      this.$emit('push-batch', value);
    },
    handleProductInput(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));
      this.packets[index].product_not_received = value;
      this.$emit('product-input');
    },
    handleBatchInput(e, index, i) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));
      this.packets[index].batch[i].attributes.jumlah = value;
      e.target.value = this.$options.filters.toCurrency(value);
      this.$emit('batch-input');
    },
  },
};
</script>
