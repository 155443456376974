<template>
  <div>
    <Datatable
      :total="getOrders.meta.page.total"
      :perPage="getOrders.meta.page.perPage"
      :currentPage="getOrders.meta.page.currentPage"
      @pagechanged="onPageChangeProducts"
      :is-empty="!getOrders.data?.length"
    >
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              No. Penjualan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Susulan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Kode Gudang
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Status Susulan
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="getOrders.data?.length > 0">
          <tr
            class="cursor-pointer bg-white hover:bg-green-100"
            v-for="(data, dataIdx) in getOrders.data"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            @click="redirectToDetail(data)"
          >
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
            >
              <div class="text-sm font-bold text-gray-900">
                {{ data.attributes.origin_code }} /
                {{ data.attributes.destination_code }}
              </div>
              <div class="text-xs font-light text-gray-300">
                {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
              </div>
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ data.attributes.item_count }}
              Produk
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ data.attributes.origin_warehouse_code }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <span
                class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
              >
                {{ data.attributes.warehouse_status }}
              </span>
            </td>
          </tr>
        </tbody>
      </template>
    </Datatable>
    <loading v-if="isLoading"></loading>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'SusulanTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isOrderLoading: 'orders/getLoading',
      stockMovementLoading: 'stock_movements/getLoading'
    }),
    isLoading() {
      return this.isOrderLoading || this.stockMovementLoading;
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    dayjs,
    onPageChangeProducts(val) {
      this.$emit('change-page', val);
    },
    redirectToDetail(val) {
      this.$emit('click-row', val);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
