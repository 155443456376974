<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard class="mb-5 rounded-lg py-4">
      <div class="flex items-center justify-end space-x-2">
        <select
          v-model="is_packed"
          @change="filterByPacked"
          class="inline-flex appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
        >
          <option :value="null">Semua</option>
          <option :value="true">Selesai</option>
          <option :value="false">Belum Selesai</option>
        </select>
        <base-search
          v-model="search"
          @input="debounceSearch"
          placeholder="Cari kode atau nama"
        />
      </div>
      <div class="flex my-2 sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <SusulanTable
            @change-page="onPageChangeProducts"
            @click-row="redirectToDetail"
          />
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';
import SusulanTable from '@/components/susulan/SusulanTable.vue';

export default {
  name: 'Susulan',
  components: {
    BaseSearch,
    SusulanTable,
  },
  data() {
    return {
      search: null,
      is_packed: false,
      page: {
        pageNumber: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.fetchOrders({
      ...this.fetchOrderParams,
    });
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    fetchOrderParams: function () {
      return {
        'page[limit]': this.page.pageSize,
        'filter[destination_office_category_id]': 4,
        'filter[order_status]': 'Ada Susulan',
        'filter[search]': this.search,
        include:
          'destination-office,order-details,destination-warehouse,origin-warehouse,payments',
        fields: {
          orders:
            'item_count,origin_code,destination_code,origin_warehouse_code,warehouse_status,updatedAt',
        },
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    dayjs,
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    }, 300),
    filterByPacked() {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    onPageChangeProducts(page) {
      this.page.pageNumber = page;
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    redirectToDetail(data) {
      this.$router.push(`barang-ke-stockist/${data.attributes.order_id}`);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
};
</script>
