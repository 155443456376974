<template>
  <OrderDetailModal
    title="Detail Order Barang"
    description="Data Order Barang"
    :visible="visible"
    :order="getOrder.data"
    :editable="false"
    :deleteable="false"
    :printable="false"
    :with-payments="false"
    :custom-action="true"
    @close="handleClose"
    @refresh="handleRefresh"
  >
    <template #detail="{ order }">
      <dl class="flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">No PO</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.destination_code }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Tanggal</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ dayjs(order.attributes.updatedAt).format('ll LT') }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Kode Kantor</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              order.relationships['origin-office'].data
                ? getRelationships(
                    getOrder,
                    order.relationships['origin-office'].data.id
                  )?.attributes.code
                : '-'
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Dibuat Oleh</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{ order.attributes.created_by?.office?.name }}/{{
              order.attributes.created_by?.user?.name
            }}
          </dd>
        </div>
      </dl>
      <dl class="mt-6 flex flex-row gap-x-4">
        <div class="w-full">
          <dt class="text-xs text-gray-700">Nama Supplier</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.name ?? '-'
                : ''
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 1</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.address ?? '-'
                : ''
            }}
          </dd>
        </div>
        <div class="w-full">
          <dt class="text-xs text-gray-700">Alamat Supplier 2</dt>
          <dd class="mt-1 text-sm font-bold text-gray-900">
            {{
              getOrder.data.relationships['origin-office']?.data
                ? getRelationships(
                    getOrder,
                    getOrder.data.relationships['origin-office'].data.id
                  )?.attributes.address2 ?? '-'
                : ''
            }}
          </dd>
        </div>
      </dl>
    </template>

    <template #order-details="{ orderDetails, order }">
      <Datatable :paginated="false" :footer="false" class="mt-4">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                JUMLAH
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                BELUM DITERIMA
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                DIKIRIM
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                BERAT
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL HARGA
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-12 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                TOTAL BERAT
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              class="bg-white"
              v-for="(barang, index) in orderDetails"
              :key="index"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{ barang.attributes.product_code }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_qty | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_not_received }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ getOrderProductShipped(barang) }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.product_weight }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 pr-12 text-right text-sm text-gray-500"
              >
                {{ barang.attributes.total_weight }}
              </td>
            </tr>
          </tbody>
          <!-- Informasi Total -->
          <tfoot>
            <tr class="bg-white">
              <td
                colspan="5"
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                Total
              </td>

              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-3 pr-12 text-right text-sm text-gray-500"
              >
                {{ order.attributes.grand_total_weight }}
              </td>
            </tr>
          </tfoot>
        </template>
      </Datatable>
    </template>

    <template #action="{ order }">
      <div class="flex justify-end space-x-2">
        <button
          @click="handleValidate(order)"
          v-if="
            stockMovementSusulan.length > 0 &&
            !stockMovementSusulan[0].attributes.is_valid_for_shipment
          "
          class="focus:outline-none inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
            />
          </svg>
          Validasi
        </button>
        <button
          @click="handleClose"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
        >
          Tutup
        </button>
      </div>
    </template>
  </OrderDetailModal>
</template>

<script>
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import Datatable from '@/components/base/Datatable';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: { OrderDetailModal, Datatable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    redirectOnClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'refresh'],
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    stockMovementSusulan: function () {
      return this.getOrder.included.filter(
        (item) =>
          item.type === 'stock-movements' && item.attributes.receivedAt === null
      );
    },
  },
  methods: {
    ...mapActions({
      updateStockMovement: 'stock_movements/updateStockMovement',
    }),
    dayjs,
    handleClose() {
      if (this.redirectOnClose) {
        this.$router.push('/gudang/susulan/barang-dari-supplier');
      } else {
        this.$emit('close');
      }
    },
    handleRefresh() {
      if (this.redirectOnClose) {
        this.$router.push('/gudang/susulan/barang-dari-supplier');
      } else {
        this.$emit('refresh');
      }
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data?.[0];
    },
    getOrderProductShipped(product) {
      return this.stockMovementSusulan[0].attributes.product_summaries[
        product.attributes.product_code
      ].shipped_product_qty;
    },
    handleValidate() {
      const payload = {
        data: {
          type: 'stock-movements',
          id: this.stockMovementSusulan[0].id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.handleRefresh();
        }
      });
    },
  },
};
</script>
