<template>
  <div>
    <BaseModal
      :showing="visible"
      @close="onClose"
      :showClose="visible"
      :backgroundClose="true"
      size="max-w-5xl"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Detail Order Barang
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Data Order Barang
            </p>
          </div>
          <div>
            <img src="@/assets/images/logo.png" alt="" class="w-32" />
          </div>
        </div>
        <div class="border-t border-gray-200 pt-6" v-if="visible">
          <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
            <slot name="attribute" :order="getOrder">
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">No PO</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getOrder.data.attributes.origin_code }} /
                  {{ getOrder.data.attributes.destination_code }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ getOrder.data.attributes.updatedAt | formatDate }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Kode Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    getSingleIncluded(
                      getOrder,
                      getOrder.data.relationships['origin-office'].data.id
                    ).attributes.code
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">Nama Supplier</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    getSingleIncluded(
                      getOrder,
                      getOrder.data.relationships['origin-office'].data.id
                    ).attributes.name
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Alamat Supplier 1
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    getSingleIncluded(
                      getOrder,
                      getOrder.data.relationships['origin-office'].data.id
                    ).attributes.address
                  }}
                </dd>
              </div>
              <div class="sm:col-span-2">
                <dt class="text-sm font-bold text-gray-700">
                  Alamat Supplier 2
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{
                    getSingleIncluded(
                      getOrder,
                      getOrder.data.relationships['origin-office'].data.id
                    ).attributes.address2 || '-'
                  }}
                </dd>
              </div>
            </slot>
            <slot name="attribute" :order="getOrder">
              <div class="sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">Daftar Barang</dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <Datatable :paginated="false" :footer="false">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode Barang
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Dikirim
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Belum Diterima
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Berat
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Harga
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Total Berat
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          v-for="orderDetail in getIncludedByType(
                            getOrder,
                            'order-details'
                          )"
                          :key="orderDetail.id"
                          class="bg-white hover:bg-green-100"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ orderDetail.attributes.product_code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.product_qty | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.product_packed | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.product_not_received
                                | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.product_weight | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.total_price | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              orderDetail.attributes.total_weight | toCurrency
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </dd>
              </div>
            </slot>
            <slot name="attribute" :order="getOrder">
              <div class="sm:col-span-6">
                <dt class="text-sm font-bold text-gray-700">
                  Daftar Pengiriman
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  <Datatable :paginated="false" :footer="false">
                    <template v-slot:thead>
                      <thead class="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Kode Pengiriman
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Tanggal
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Jumlah Barang
                          </th>
                          <th
                            scope="col"
                            class="px-6 py-3 text-left text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                    </template>
                    <template v-slot:tbody>
                      <tbody>
                        <tr
                          v-for="stockMovement in getIncludedByType(
                            getOrder,
                            'stock-movements'
                          )"
                          :key="stockMovement.id"
                          class="cursor-pointer bg-white hover:bg-green-100"
                          v-on:click="onDetailStockMovement(stockMovement)"
                        >
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                          >
                            {{ stockMovement.attributes.code }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                          >
                            {{
                              stockMovement.attributes.updatedAt | formatDate
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                          >
                            {{
                              Object.keys(
                                stockMovement.attributes.product_summaries
                              ).length | toCurrency
                            }}
                          </td>
                          <td
                            class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                          >
                            <span
                              v-if="!stockMovement.attributes.is_valid_for_shipment"
                              class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-800"
                            >
                              Belum Divalidasi
                            </span>
                            <span
                              v-else-if="!stockMovement.attributes.is_shipped"
                              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800"
                            >
                              Belum Dikirim
                            </span>
                            <span
                              v-else
                              class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                            >
                              Dikirim
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </Datatable>
                </dd>
              </div>
            </slot>
          </dl>
        </div>
        <div class="flex justify-end gap-x-2">
          <button
            type="button"
            v-if="hasSusulan"
            class="focus:outline-none inline-flex items-center justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
            v-on:click="onCreateSusulan"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="mr-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
              />
            </svg>
            Buat Susulan
          </button>
          <button
            v-on:click="onClose"
            type="button"
            class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
          >
            Tutup
          </button>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseModal from '@/components/base/BaseModal.vue';
import Datatable from '@/components/base/Datatable.vue';

export default {
  name: 'SupplierSusulanDetailModal',
  components: { BaseModal, Datatable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'refresh', 'detail-stock-movement'],
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
    }),
    hasSusulan() {
      return (
        this.getOrder.included.filter(
          (item) =>
            item.type === 'stock-movements' &&
            item.attributes.receivedAt === null
        ).length === 0
      );
    },
  },
  methods: {
    ...mapActions({
      sendStockMovement: 'stock_movements/sendStockMovement',
    }),
    onClose() {
      this.$emit('close');
    },
    onDetailStockMovement(stockMovement) {
      this.$emit('detail-stock-movement', stockMovement.id)
    },
    onCreateSusulan() {
      this.$router.push({
        name: 'gudang.susulan.barang-dari.supplier.tambah',
        params: {
          id: this.getOrder.data.id,
        },
      });
    }
  },
};
</script>
