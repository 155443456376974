<template>
  <div v-if="isLoading" class="justify-center text-center">
    <loading></loading>
  </div>
  <div v-else class="mx-4 py-4 md:mx-6 md:py-6">
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div
        class="flex flex-wrap items-center justify-between border-b border-gray-200 pb-4 sm:flex-nowrap"
      >
        <div class="mt-2 w-full">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Informasi Pengemasan
          </h3>
        </div>
      </div>
      <div class="py-4">
        <dl class="flex flex-row gap-x-4">
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">No Penjualan</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ dataPenjualan.data.attributes.origin_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">Tanggal</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{ dayjs(dataPenjualan.data.attributes.tanggal).format('ll LT') }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">Kode Stockist</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                this.getRelationships(
                  dataPenjualan.included,
                  dataPenjualan.data.relationships['destination-office'].data.id
                ).attributes.code
              }}
            </dd>
          </div>
        </dl>
        <dl class="flex mt-6 flex-row gap-x-4">
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">
              Nama Stockist Center
            </dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                this.getRelationships(
                  dataPenjualan.included,
                  dataPenjualan.data.relationships['destination-office'].data.id
                ).attributes.name
              }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">Alamat Stockist 1</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                this.getRelationships(
                  dataPenjualan.included,
                  dataPenjualan.data.relationships['destination-office'].data.id
                ).attributes.address
              }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-sm font-bold text-gray-700">Alamat Stockist 2</dt>
            <dd class="mt-1 text-sm text-gray-900">
              {{
                this.getRelationships(
                  dataPenjualan.included,
                  dataPenjualan.data.relationships['destination-office'].data.id
                ).attributes.address2
              }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="mb-5 space-y-6 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">Tambah Koli</h3>
        <p class="mt-2 max-w-4xl text-sm text-gray-500">
          Tambahkan barang yang akan dikemas
        </p>
      </div>
      <!-- Replace with your content -->
      <div
        v-for="(koli, indexKoli) in dataPengemasan.listKoli"
        :key="indexKoli"
        class="mt-2 mb-5 rounded-lg border border-gray-200 bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="mb-8 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-12">
          <div class="sm:col-span-10">
            <div class="field-inset-default w-full">
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Koli
              </label>
              <input
                v-model="koli.name"
                type="text"
                name="kode_po"
                id="kode_po"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                :placeholder="`Contoh: Koli ${indexKoli + 1}`"
              />
            </div>
          </div>
          <div class="sm:col-span-1">
            <button
              @click="duplicateKoli(indexKoli)"
              :disabled="!koli.products.length > 0"
              class="field-inset-default w-full border border-gray-500 bg-gray-100 text-gray-500"
              :class="
                koli.products.length > 0
                  ? 'border border-green-500 bg-green-100 text-green-500'
                  : null
              "
            >
              <div class="flex items-center justify-center py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400"
                  :class="koli.products.length > 0 ? 'text-green-500' : null"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    d="M7 9a2 2 0 012-2h6a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9z"
                  />
                  <path
                    d="M5 3a2 2 0 00-2 2v6a2 2 0 002 2V5h8a2 2 0 00-2-2H5z"
                  />
                </svg>
                <p
                  class="ml-1 text-xs text-gray-400"
                  :class="koli.products.length > 0 ? 'text-green-500' : null"
                >
                  Duplikat Koli
                </p>
              </div>
            </button>
          </div>
          <div class="sm:col-span-1">
            <button
              @click="removeKoli(indexKoli, koli)"
              class="field-inset-default w-full border border border-gray-500 border-red-500 bg-gray-100 bg-red-100 text-gray-500 text-red-500"
            >
              <div class="flex items-center justify-center py-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-gray-400 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <p class="ml-1 text-xs text-gray-400 text-red-500">
                  Hapus Koli
                </p>
              </div>
            </button>
          </div>
        </div>
        <Datatable :paginated="false" :footer="false">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  KODE BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  NAMA BARANG
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  JUMLAH
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  AKSI
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="koli.products.length > 0">
              <tr
                class="bg-white"
                v-for="(barang, indexBarang) in koli.products"
                :key="indexBarang"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  {{ barang.product_code }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  {{ barang.product_name }}
                </td>
                <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                  <input
                    :value="
                      dataPengemasan.listKoli[indexKoli].products[indexBarang]
                        .product_not_packed
                    "
                    @change="
                      changeProduct(
                        this,
                        indexKoli,
                        indexBarang,
                        barang,
                        $event
                      )
                    "
                    type="text"
                    name="kode_details"
                    class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Jumlah..."
                  />
                </td>
                <td
                  class="flex justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                >
                  <button
                    @click="removeProduct(indexKoli, indexBarang, barang)"
                    type="button"
                  >
                    <svg
                      class="cursor-pointer"
                      width="15"
                      height="16"
                      viewBox="0 0 15 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.5 0C6.12123 0 5.77497 0.214002 5.60557 0.552786L4.88197 2H1.5C0.947715 2 0.5 2.44772 0.5 3C0.5 3.55228 0.947716 4 1.5 4L1.5 14C1.5 15.1046 2.39543 16 3.5 16H11.5C12.6046 16 13.5 15.1046 13.5 14V4C14.0523 4 14.5 3.55228 14.5 3C14.5 2.44772 14.0523 2 13.5 2H10.118L9.39443 0.552786C9.22504 0.214002 8.87877 0 8.5 0H6.5ZM4.5 6C4.5 5.44772 4.94772 5 5.5 5C6.05228 5 6.5 5.44772 6.5 6V12C6.5 12.5523 6.05228 13 5.5 13C4.94772 13 4.5 12.5523 4.5 12V6ZM9.5 5C8.94771 5 8.5 5.44772 8.5 6V12C8.5 12.5523 8.94771 13 9.5 13C10.0523 13 10.5 12.5523 10.5 12V6C10.5 5.44772 10.0523 5 9.5 5Z"
                        fill="#EF4444"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <EmptyKoliLabel @click="openModalOrder(indexKoli)" />
            </tbody>
          </template>
        </Datatable>
        <div v-if="koli.products.length > 0" class="flex mt-4 justify-end">
          <button
            @click="openModalOrder(indexKoli)"
            type="button"
            class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Tambah Produk
          </button>
        </div>
      </div>
      <div class="flex justify-end space-x-2">
        <button
          @click="addPackage"
          type="button"
          class="ml-3 rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-xs font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Tambah Koli
        </button>
      </div>
    </div>

    <div class="mb-5 space-y-6 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">Sisa Barang</h3>
        <p class="mt-2 max-w-4xl text-sm text-gray-500">
          Sisa barang yang belum dikemas
        </p>
      </div>
      <Datatable :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                NAMA BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                JUMLAH
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody v-if="selisihBarang.length > 0">
            <tr
              class="bg-white"
              v-for="(barang, index) in selisihBarang"
              :key="index"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{ barang.product_code }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ barang.product_name }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ barang.product_not_packed }}
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="bg-white">
              <td
                class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900"
                colspan="3"
              >
                <div class="flex my-4 items-center justify-center space-y-2">
                  <button type="button">
                    <div class="flex mb-2 justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                        />
                      </svg>
                    </div>
                    <p class="text-center text-xs font-normal text-gray-400">
                      Tidak ada data barang <br />
                      yang belum dikemas
                    </p>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </div>
    <div class="flex justify-end">
      <div>
        <button
          @click="openModalConfirmation"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Dikemas
        </button>
        <router-link
          to="/gudang/susulan/barang-ke-stockist"
          class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Kembali
        </router-link>
      </div>
    </div>

    <!-- Order List -->

    <BaseModal
      :showing="openOrderList"
      @close="openOrderList = !openOrderList"
      :showClose="openOrderList"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <div class="flex justify-between px-4 py-5 sm:px-6">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Barang
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Data barang dari order yang telah dibuat
          </p>
        </div>
        <div>
          <img src="@/assets/images/logo.png" alt="" />
        </div>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <div class="flex justify-end">
          <base-search v-model="search" placeholder="Cari kode atau nama" />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable :footer="false">
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Kode Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Barang
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Jumlah
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Total Produk
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody v-if="selisihBarang.length > 0">
                  <tr
                    class="cursor-pointer bg-white"
                    v-for="(data, dataIdx) in selisihBarang"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <input
                        v-model="tempKoli"
                        aria-describedby="cetak-faktur"
                        name="cetak-faktur"
                        type="checkbox"
                        :value="data"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      {{ data.product_code }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.product_name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <input
                        v-model="data.product_taken"
                        type="text"
                        :disabled="!tempKoli.includes(data)"
                        name="jumlah"
                        class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                        placeholder="Masukkan jumlah..."
                      />
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ data.product_not_packed }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm font-medium text-gray-900"
                      colspan="5"
                    >
                      <div
                        class="flex my-4 items-center justify-center space-y-2"
                      >
                        <button type="button">
                          <div class="flex mb-2 justify-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 w-6 text-gray-400"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                              />
                            </svg>
                          </div>
                          <p
                            class="text-center text-xs font-normal text-gray-400"
                          >
                            Tidak ada data barang
                            <br />
                            yang belum dikemas
                          </p>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
      <div class="flex justify-end space-x-2">
        <button
          @click="confirmation"
          type="button"
          class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
        >
          Konfirmasi
        </button>
        <button
          @click="openOrderList = !openOrderList"
          type="button"
          class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Kembali
        </button>
      </div>
    </BaseModal>

    <!-- MODAL VALIDASI PENGEMASAN-->
    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="validateConfirmation"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="sendKoli">
              <div>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    class="h-6 w-6 text-green-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Buat Koli
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Apakah anda yakin data yang dibuat sudah benar?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="submit"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Ya
                </button>
                <button
                  @click="validateConfirmation = !validateConfirmation"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Kembali
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>

    <!-- MODAL VALIDASI PENGEMASAN-->
    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="warningConfirmation"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex mx-4 min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="sendKoli">
              <div>
                <div
                  class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-yellow-100"
                >
                  <!-- Heroicon name: outline/check -->
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-yellow-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Barang sisa ditemukan
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Barang yang sisa akan dimasukkan ke Susulan, apakah anda
                      yakin akan melanjutkan?
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="submit"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Ya
                </button>
                <button
                  @click="warningConfirmation = !warningConfirmation"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Kembali
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Loading from '@/components/Loading.vue';
// import Modal from "@/components/Modal.vue";
import Datatable from '@/components/base/Datatable';
import BaseModal from '@/components/base/BaseModal';
import EmptyKoliLabel from '@/components/pengeluaran/EmptyKoliLabel.vue';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'DetailPengeluaran',
  components: {
    Datatable,
    Loading,
    BaseModal,
    EmptyKoliLabel,
    BaseSearch,
    // Modal,
  },
  data() {
    return {
      isLoading: false,
      is_detail: false,
      openOrderList: false,
      validateConfirmation: false,
      warningConfirmation: false,
      search: null,
      indexKoli: null,
      dataPenjualan: {},
      dataPengemasan: {
        listKoli: [
          {
            name: 'Koli 1',
            code: null,
            products: [],
          },
        ],
      },
      tempKoli: [],
      order_details: [],
      stockMovement: null,
    };
  },
  created() {
    this.isLoading = true;
    if (this.$route.params.id) {
      this.fetchOrder({
        id: this.$route.params.id,
        fields: {
          orders:
            'origin_code,destination_code,final_price,payment_amount,order_shipment,is_packed,is_paid,print_invoice_enabled,warehouse_status,origin-warehouse,destination-warehouse,origin-office,destination-office,stock-movements,order-details',
          offices: 'code,name,address',
          // 'order-details': 'product_code,product_qty,product_weight,total_price,total_weight,product_not_packed'
        },
        include:
          'origin-warehouse,destination-warehouse,origin-office,destination-office,stock-movements,order-details',
      }).then((response) => {
        this.dataPenjualan = response.data;
        this.dataPenjualan.data.attributes.order_details =
          response.data.data.relationships['order-details'].data
            .map((relationship) =>
              this.getRelationships(response.data.included, relationship.id)
            )
            .map((orderDetails) => orderDetails.attributes);
        this.isLoading = false;
      });
    }
    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({}),
    selisihBarang() {
      return this.dataPenjualan.data.attributes.order_details.filter(
        (product) =>
          product.product_not_packed > 0 &&
          (product.product_code
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
            product.product_name
              .toLowerCase()
              .includes(this.search.toLowerCase()))
      );
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      createStockMovement: 'stock_movements/createStockMovement',
      updateStockMovement: 'stock_movements/updateStockMovement',
    }),
    openModalOrder(index) {
      this.indexKoli = index;
      this.tempKoli = [];
      this.openOrderList = !this.openOrderList;
    },
    openModalConfirmation() {
      if (this.selisihBarang.length > 0) {
        this.warningConfirmation = true;
      } else {
        this.validateConfirmation = true;
      }
    },
    getRelationships(resource, id) {
      let data = resource.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    addPackage() {
      const index = this.dataPengemasan.listKoli.length + 1;
      this.dataPengemasan.listKoli.push({
        name: 'Koli ' + index,
        code: null,
        products: [],
      });
    },
    confirmation() {
      this.openOrderList = !this.openOrderList;
      this.tempKoli.map((temp) => {
        const index =
          this.dataPenjualan.data.attributes.order_details.findIndex(
            (product) => product.product_id === temp.product_id
          );
        this.dataPengemasan.listKoli[this.indexKoli].products.push({
          product_id: temp.product_id,
          product_code: temp.product_code,
          product_name: temp.product_name,
          product_not_packed:
            this.dataPenjualan.data.attributes.order_details[index]
              .product_not_packed > temp.product_taken
              ? temp.product_taken
              : this.dataPenjualan.data.attributes.order_details[index]
                  .product_not_packed,
          product_price: temp.product_price,
          total_price: temp.product_not_packed * temp.product_price,
          no_batch: null,
          expired_at: null,
        });
        if (
          this.dataPenjualan.data.attributes.order_details[index]
            .product_not_packed > temp.product_taken
        ) {
          this.dataPenjualan.data.attributes.order_details[
            index
          ].product_not_packed -= temp.product_taken;
        } else {
          this.dataPenjualan.data.attributes.order_details[
            index
          ].product_not_packed = 0;
        }
        this.dataPenjualan.data.attributes.order_details[index].product_taken =
          null;
      });
    },
    duplicateKoli(indexKoli) {
      const duplicate = {};
      duplicate.name = this.dataPengemasan.listKoli[indexKoli].name + ' (copy)';
      duplicate.code = null;
      duplicate.products = [];
      this.dataPengemasan.listKoli[indexKoli].products.map((temp) => {
        let tempQty = 0;
        const index =
          this.dataPenjualan.data.attributes.order_details.findIndex(
            (product) => product.product_id === temp.product_id
          );
        if (
          this.dataPenjualan.data.attributes.order_details[index]
            .product_not_packed > temp.product_not_packed
        ) {
          tempQty = temp.product_not_packed;
          this.dataPenjualan.data.attributes.order_details[
            index
          ].product_not_packed -= temp.product_not_packed;
        } else {
          tempQty =
            this.dataPenjualan.data.attributes.order_details[index]
              .product_not_packed;
          this.dataPenjualan.data.attributes.order_details[
            index
          ].product_not_packed = 0;
        }
        duplicate.products.push({
          expired_at: temp.expired_at,
          no_batch: temp.no_batch,
          product_code: temp.product_code,
          product_id: temp.product_id,
          product_name: temp.product_name,
          product_price: temp.product_price,
          product_not_packed: tempQty,
          total_price: temp.product_price * tempQty,
        });
      });
      this.dataPengemasan.listKoli.push(duplicate);
    },
    removeKoli(indexKoli, koli) {
      koli.products.map((temp) => {
        const index =
          this.dataPenjualan.data.attributes.order_details.findIndex(
            (product) => product.product_id === temp.product_id
          );
        this.dataPenjualan.data.attributes.order_details[
          index
        ].product_not_packed =
          parseInt(
            this.dataPenjualan.data.attributes.order_details[index]
              .product_not_packed
          ) + parseInt(temp.product_not_packed);
      });
      this.dataPengemasan.listKoli.splice(indexKoli, 1);
    },
    removeProduct(indexKoli, indexBarang, products) {
      this.dataPengemasan.listKoli[indexKoli].products.splice(indexBarang, 1);
      const index = this.dataPenjualan.data.attributes.order_details.findIndex(
        (product) => product.product_id === products.product_id
      );
      this.dataPenjualan.data.attributes.order_details[
        index
      ].product_not_packed =
        parseInt(
          this.dataPenjualan.data.attributes.order_details[index]
            .product_not_packed
        ) + parseInt(products.product_not_packed);
    },
    changeProduct(element, indexKoli, indexBarang, products, event) {
      const newValue = parseInt(event.target.value);
      const index = this.dataPenjualan.data.attributes.order_details.findIndex(
        (product) => product.product_id === products.product_id
      );
      if (
        newValue >
        this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
          .product_not_packed
      ) {
        if (
          newValue >
          this.dataPenjualan.data.attributes.order_details[index]
            .product_not_packed +
            this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
              .product_not_packed
        ) {
          window.alert('Jumlah melebih batas');
        } else {
          this.dataPenjualan.data.attributes.order_details[
            index
          ].product_not_packed =
            this.dataPenjualan.data.attributes.order_details[index]
              .product_not_packed +
            this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
              .product_not_packed -
            newValue;
          this.dataPengemasan.listKoli[indexKoli].products[
            indexBarang
          ].product_not_packed = newValue;
          this.dataPengemasan.listKoli[indexKoli].products[
            indexBarang
          ].total_price =
            this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
              .product_not_packed *
            this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
              .product_price;
        }
      } else {
        this.dataPenjualan.data.attributes.order_details[
          index
        ].product_not_packed =
          this.dataPenjualan.data.attributes.order_details[index]
            .product_not_packed +
          this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
            .product_not_packed -
          newValue;
        this.dataPengemasan.listKoli[indexKoli].products[
          indexBarang
        ].product_not_packed = newValue;
        this.dataPengemasan.listKoli[indexKoli].products[
          indexBarang
        ].total_price =
          this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
            .product_not_packed *
          this.dataPengemasan.listKoli[indexKoli].products[indexBarang]
            .product_price;
      }
    },
    getOrderDetails(params) {
      let packets = [];
      params.map((param) => {
        if (param.products.length > 0) {
          let koli = {};
          koli.name = param.name;
          koli.code = param.code;
          koli.products = [];
          param.products.map((product) => {
            let data = {
              product_id: product.product_id,
              shipped_product_qty: product.product_not_packed,
            };
            koli.products.push(data);
          });
          packets.push(koli);
        }
      });
      return packets;
    },
    sendKoli() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_warehouse_id:
              this.dataPenjualan.data.relationships['origin-warehouse'].data.id,
            destination_warehouse_id:
              this.dataPenjualan.data.relationships['destination-warehouse']
                .data.id,
            origin_office_id:
              this.dataPenjualan.data.relationships['origin-office'].data.id,
            destination_office_id:
              this.dataPenjualan.data.relationships['destination-office'].data
                .id,
            packets: this.getOrderDetails(this.dataPengemasan.listKoli),
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataPenjualan.data.id,
              },
            },
          },
        },
      };
      if (this.is_detail) {
        payload.data.id =
          this.dataPenjualan.data.relationships['stock-movements'].data[0].id;
        this.updateStockMovement(payload).then((response) => {
          if (response) {
            this.success = true;
            this.$router.push('/gudang/susulan/barang-ke-sc');
          } else {
            this.failed = true;
          }
        });
      } else {
        this.createStockMovement(payload).then((response) => {
          if (response) {
            this.success = true;
            this.$router.push('/gudang/susulan/barang-ke-sc');
          } else {
            this.failed = true;
          }
        });
      }
    },
  },
};
</script>
