<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div class="mb-5 rounded-lg py-4">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-input label="Status">
            <base-select
              @change="filterByPacked"
              :options="statusOptions"
              v-model="is_packed"
            />
          </base-input>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari kode atau nama"
          />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <SusulanTable
              @change-page="onPageChangeProducts"
              @click-row="redirectToDetail"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import SusulanTable from '@/components/susulan/SusulanTable.vue';

export default {
  name: 'Users',
  components: {
    BaseSearch,
    SusulanTable,
  },
  data() {
    return {
      search: null,
      is_packed: false,
      page: {
        pageNumber: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.fetchOrders({
      ...this.fetchOrderParams,
    });
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    fetchOrderParams: function () {
      return {
        pageNumber: this.page.pageNumber,
        pageSize: this.page.pageSize,
        origin_warehouse_id: StorageService.getUser().current_warehouse,
        destination_office_category: 'Stockist Center',
        is_pending_follow_up_delivery: true,
        is_valid_for_shipment: false,
        has_stock_movement: true,
        is_packed: this.is_packed,
        search: this.search,
        include:
          'destination-office,order-details,destination-warehouse,origin-warehouse,payments',
        fields: {
          orders:
            'item_count,origin_code,destination_code,origin_warehouse_code,warehouse_status,updatedAt',
        },
      };
    },
    statusOptions() {
      return [
        {
          label: 'Semua',
          value: null,
        },
        {
          label: 'Selesai',
          value: true,
        },
        {
          label: 'Belum Selesai',
          value: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    }, 300),
    filterByPacked() {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    onChangeComplain() {
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    onPageChangeProducts(page) {
      this.page.pageNumber = page;
      this.fetchOrders({
        ...this.fetchOrderParams,
      });
    },
    redirectToDetail(data) {
      this.$router.push(`barang-ke-sc/${data.id}`);
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
};
</script>
