<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <div v-if="isLoading" class="justify-center text-center">
      <loading></loading>
    </div>
    <div class="mb-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">
          Informasi Supplier
        </h3>
      </div>
      <div class="py-2">
        <div class="mt-6 grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <div
              class="field-inset-default relative w-full"
              :class="{ 'bg-gray-100': is_detail }"
            >
              <label
                for="kode_po"
                class="block text-xs font-bold text-gray-700"
              >
                Kode PO
              </label>
              <input
                v-model="dataBrgMasuk.no_po"
                disabled
                type="text"
                name="kode_po"
                id="kode_po"
                class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                :class="{ 'bg-gray-100': is_detail }"
                placeholder="Masukkan Kode Supplier"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default w-full bg-gray-100">
              <label
                for="tanggal"
                class="block text-xs font-bold text-gray-700"
              >
                Tanggal
              </label>
              <input
                v-model="dataBrgMasuk.po_date"
                type="date"
                name="tanggal"
                id="tanggal"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="kode_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Kode Supplier
              </label>
              <input
                v-model="dataBrgMasuk.kode_supplier"
                type="text"
                name="kode_supplier"
                id="kode_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Kode Supplier..."
                disabled
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_supplier"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Supplier
              </label>
              <input
                v-model="dataBrgMasuk.nama_supplier"
                type="text"
                name="nama_supplier"
                id="nama_supplier"
                class="block w-full border-0 bg-gray-100 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                placeholder="Nama Supplier..."
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <SupplierBatchForm
      v-model="dataBrgMasuk"
      :packets="packets"
      @push-batch="onPushBatch"
      @remove-product="onRemoveProduct"
      @remove-batch="onRemoveItem"
      @batch-input="onBatchInput"
      @product-input="onProductInput"
    />

    <div class="pt-5">
      <div class="flex justify-end">
        <button
          :disabled="!saveable"
          :class="saveable ? '' : 'cursor-not-allowed opacity-50'"
          @click="addIncomingGood"
          class="tooltip ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg"
        >
          Simpan
          <p style="width: fit-content" v-if="!saveable" class="tooltiptext">
            Jumlah barang harus pas dan form terisi semua
          </p>
        </button>
        <button
          @click="goBack()"
          class="ml-3 inline-flex rounded-md border border-gray-300 bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-600 hover:shadow-lg"
        >
          Selesai
        </button>
      </div>
    </div>

    <SupplierSusulanModal :visible="success" redirect-on-close />

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <modal
          @modal-action="failedModal"
          link="/gudang/susulan/barang-dari-supplier"
        >
          <template v-slot:svg>
            <div
              class="flex mx-auto h-12 w-12 items-center justify-center rounded-full bg-red-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-red-600"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </template>
          <template v-slot:heading>
            <h3
              class="text-lg font-bold leading-6 text-gray-900"
              id="modal-title"
            >
              {{ getError[0].detail }}
            </h3>
          </template>
        </modal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';
import Loading from '@/components/Loading.vue';
import SupplierSusulanModal from '@/components/supplier/SupplierSusulanModal.vue';
import { clone } from '@/services/utils.service.js';

// import InformasiBarangKeluar from '@/components/pengeluaran/informasi-barang-keluar.vue';
import SupplierBatchForm from '@/components/supplier/SupplierBatchForm.vue';
export default {
  name: 'TambahPenjualan',
  components: {
    Modal,
    Loading,
    SupplierSusulanModal,
    // InformasiBarangKeluar,
    SupplierBatchForm,
  },
  data() {
    return {
      id_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      search: null,
      dataBrgMasuk: {
        id_po: null,
        origin_warehouse_id: null,
        destination_warehouse_id: null,
        origin_office_id: null,
        destination_office_id: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      },
      packets: [],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.is_detail = true;
      this.dataBrgMasuk.id_po = this.$route.params.id;
      this.fetchOrder({
        id: this.dataBrgMasuk.id_po,
        include:
          'destination-office,origin-office,origin-warehouse,destination-warehouse,order-details,stock-movements,order-details',
        // fields: {
        //   orders:
        //     'createdAt,updatedAt,origin_code,created_by,destination_code,final_price,is_completed,is_valid_for_payment,is_shipped,final_price,grand_total_weight,item_count,destination-office,destination-warehouse,origin-office,origin-warehouse,order-details,stock-movements,order-details',
        //   offices: 'code,name,address,address2',
        //   'order-details':
        //     'product_id,product_code,product_qty,product_weight,product_weight,total_weight,product_not_received,total_price,is_completed,product_name,product_price',
        // },
      }).then((response) => {
        this.setOrder(response.data);
      });
    }

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoading: 'orders/getLoading',
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getError: 'stock_movements/getError',
    }),
    packetsPayload() {
      return [
        {
          name: 'Koli 1',
          products: this.packets.map((data) => ({
            product_id: data.product_id,
            shipped_product_qty: data.product_not_received,
            no_batch: data.no_batch,
            expired_at: data.expired_at,
            batches: data.batch.map((item) => {
              return {
                no_batch: item.attributes.no_batch ?? '',
                product_qty: parseInt(item.attributes.jumlah),
                expires_at: item.attributes.expired_at ?? '',
              };
            }),
          })),
        },
      ];
    },
    saveable() {
      return this.packets
        .map((i) => {
          const subtotal = i.batch.reduce(
            (acc, curr) => acc + parseInt(curr.attributes.jumlah),
            0
          );
          return (
            subtotal === parseInt(i.product_not_received) &&
            i.batch.every((batch) => !!batch.attributes.expired_at) &&
            i.batch.every((batch) => !!batch.attributes.no_batch)
          );
        })
        .every((bool) => bool); //klo ada yg false, jadiin false semua
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchWarehouseByOffice: 'warehouses/fetchWarehouseByOffice',
      createStockMovement: 'stock_movements/createStockMovement',
    }),
    successModal() {
      this.success = !this.success;
      this.openDetail = true;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    changeTotal(qty, index) {
      this.dataBrgMasuk.details[index].attributes.total_price =
        this.dataBrgMasuk.details[index].attributes.product_not_received *
        this.dataBrgMasuk.details[index].attributes.product_price;
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getOrderRelationships(id) {
      let data = this.getOrder.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    removeProduct(index) {
      this.dataBrgMasuk.details.splice(index, 1);
      this.packets.splice(index, 1);
    },
    getOrderDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        let data = {
          product_id: param.product_id,
          shipped_product_qty: param.product_not_received,
          no_batch: param.no_batch,
          expired_at: param.expired_at,
        };
        dataDetail.push(data);
      });
      return {
        name: 'Koli 1',
        products: dataDetail,
      };
    },
    addIncomingGood() {
      const payload = {
        data: {
          type: 'stock-movements',
          attributes: {
            origin_warehouse_id: this.dataBrgMasuk.origin_warehouse_id,
            destination_warehouse_id:
              this.dataBrgMasuk.destination_warehouse_id,
            origin_office_id: this.dataBrgMasuk.origin_office_id,
            destination_office_id: this.dataBrgMasuk.destination_office_id,
            packets: this.packetsPayload,
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataBrgMasuk.id_po,
              },
            },
          },
        },
      };
      this.createStockMovement(payload).then((response) => {
        if (response) {
          this.fetchOrder({ id: this.dataBrgMasuk.id_po }).then(
            () => (this.success = true)
          );
        } else {
          this.failed = true;
        }
      });
    },
    clearData() {
      this.dataBrgMasuk = {
        id_po: null,
        no_po: null,
        id_supplier: null,
        kode_supplier: null,
        nama_supplier: null,
        po_date: dayjs().format('YYYY-MM-DD'),
        details: [],
      };
    },
    goBack() {
      this.$router.go(-1);
    },
    setOrder(order) {
      this.dataBrgMasuk.no_po = order.data.attributes.destination_code;
      this.dataBrgMasuk.id_po = order.data.id;
      this.dataBrgMasuk.no_po = order.data.attributes.destination_code;
      this.dataBrgMasuk.origin_office_id =
        order.data.relationships['origin-office'].data.id;
      this.dataBrgMasuk.destination_office_id =
        order.data.relationships['destination-office'].data.id;
      this.dataBrgMasuk.id_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).id;
      this.dataBrgMasuk.kode_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).attributes.code;
      this.dataBrgMasuk.nama_supplier = this.getOrderRelationships(
        order.data.relationships['origin-office'].data.id
      ).attributes.name;

      this.dataBrgMasuk.details = order.included.filter(
        (it) => it.type === 'order-details' && it.attributes.is_completed === 0
      );
      this.packets = this.dataBrgMasuk.details.map((data) => ({
        product_id: data.attributes.product_id,
        shipped_product_qty: data.attributes.product_not_received,
        product_not_received: data.attributes.product_not_received,
        no_batch: null,
        expired_at: null,
        batch: [clone(this.dataBrgMasuk.details[0])],
      }));

      this.dataBrgMasuk.origin_warehouse_id =
        order.data.relationships['origin-warehouse'].data.id;
      this.dataBrgMasuk.destination_warehouse_id =
        order.data.relationships['destination-warehouse'].data.id;
    },
    onPushBatch(index) {
      this.packets[index].batch.push(clone(this.dataBrgMasuk.details[index]));
    },
    onRemoveProduct(index) {
      this.dataBrgMasuk.details.splice(index, 1);
      this.packets.splice(index, 1);
    },
    onRemoveItem({ index, i }) {
      this.packets[index].batch.splice(i, 1);
    },
    onBatchInput() {
      this.dataBrgMasuk = clone(this.dataBrgMasuk);
      this.packets = clone(this.packets);
    },
    onProductInput() {
      this.dataBrgMasuk = clone(this.dataBrgMasuk);
      this.packets = clone(this.packets);
    },
  },
};
</script>
